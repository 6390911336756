<template>
  <div>
    <v-toolbar flat style="display: flex; justify-content: left">
      <v-toolbar-title>Retention </v-toolbar-title>
      <div style="width: 15px;"></div>
      <v-combobox v-model="format" :items="formatItems" item-value="id" item-text="value" @change="initialize"
        hide-details label="Show Type"></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dates" label="Picker in menu" prepend-icon="mdi-calendar" readonly single-line
            hide-details v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="initialize">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-toolbar>


    <v-data-table :headers="headers" :items="this.$store.state.retentions.retention" class="elevation-1">
      <template v-slot:item.dt="{ item }">
        <div><b style="color: burlywood;">{{ convertTRDate(item.dt) }}</b><br>

          <b>{{ item.tdrc }} Register</b>
        </div>
      </template>

      <template v-slot:item.o="{ item }">
        <div>{{ getRetentionValue(item, 0) }}</div>
      </template>

      <template v-slot:item.t="{ item }">
        <div>{{ getRetentionValue(item, 1) }}</div>
      </template>

      <template v-slot:item.thr="{ item }">
        <div>{{ getRetentionValue(item, 2) }}</div>
      </template>

      <template v-slot:item.fo="{ item }">
        <div>{{ getRetentionValue(item, 3) }}</div>
      </template>

      <template v-slot:item.fv="{ item }">
        <div>{{ getRetentionValue(item, 4) }}</div>
      </template>

      <template v-slot:item.sx="{ item }">
        <div>{{ getRetentionValue(item, 5) }}</div>
      </template>

      <template v-slot:item.sv="{ item }">
        <div>{{ getRetentionValue(item, 6) }}</div>
      </template>

      <template v-slot:item.eg="{ item }">
        <div>{{ getRetentionValue(item, 7) }}</div>
      </template>

      <template v-slot:item.ni="{ item }">
        <div>{{ getRetentionValue(item, 8) }}</div>
      </template>

      <template v-slot:item.tn="{ item }">
        <div>{{ getRetentionValue(item, 9) }}</div>
      </template>

      <template v-slot:item.el="{ item }">
        <div>{{ getRetentionValue(item, 10) }}</div>
      </template>

      <template v-slot:item.tw="{ item }">
        <div>{{ getRetentionValue(item, 11) }}</div>
      </template>

      <template v-slot:item.thn="{ item }">
        <div>{{ getRetentionValue(item, 12) }}</div>
      </template>

      <template v-slot:item.fon="{ item }">
        <div>{{ getRetentionValue(item, 13) }}</div>
      </template>

      <template v-slot:item.fvn="{ item }">
        <div>{{ getRetentionValue(item, 14) }}</div>
      </template>

      <template v-slot:item.sxn="{ item }">
        <div>{{ getRetentionValue(item, 15) }}</div>
      </template>

      <template v-slot:item.svn="{ item }">
        <div>{{ getRetentionValue(item, 16) }}</div>
      </template>

      <template v-slot:item.egn="{ item }">
        <div>{{ getRetentionValue(item, 17) }}</div>
      </template>

      <template v-slot:item.nnn="{ item }">
        <div>{{ getRetentionValue(item, 18) }}</div>
      </template>

      <template v-slot:item.twty="{ item }">
        <div>{{ getRetentionValue(item, 19) }}</div>
      </template>

      <template v-slot:item.two="{ item }">
        <div>{{ getRetentionValue(item, 20) }}</div>
      </template>

      <template v-slot:item.twt="{ item }">
        <div>{{ getRetentionValue(item, 21) }}</div>
      </template>

      <template v-slot:item.twth="{ item }">
        <div>{{ getRetentionValue(item, 22) }}</div>
      </template>

      <template v-slot:item.twfo="{ item }">
        <div>{{ getRetentionValue(item, 23) }}</div>
      </template>

      <template v-slot:item.twfv="{ item }">
        <div>{{ getRetentionValue(item, 24) }}</div>
      </template>

      <template v-slot:item.twsx="{ item }">
        <div>{{ getRetentionValue(item, 25) }}</div>
      </template>

      <template v-slot:item.twsv="{ item }">
        <div>{{ getRetentionValue(item, 26) }}</div>
      </template>

      <template v-slot:item.tweg="{ item }">
        <div>{{ getRetentionValue(item, 27) }}</div>
      </template>

      <template v-slot:item.twnn="{ item }">
        <div>{{ getRetentionValue(item, 28) }}</div>
      </template>

      <template v-slot:item.thy="{ item }">
        <div>{{ getRetentionValue(item, 29) }}</div>
      </template>

      <!-- <template v-slot:slot>
        <v-row>
          <v-col
            v-for="(aggValue, index) in all.data.a"
            :key="index"
            :cols="1"
            style="white-space: nowrap; text-align: center;"
          >
            {{ aggValue }}
          </v-col>
        </v-row>
      </template> -->
      <template v-slot:body.append="props">
        <tr style="background-color:rgb(4, 29, 66);">
          <td v-for="(header, index) in headers" :key="index">
            <b v-if="index===0">Aggregates</b>
            <b style="color: rgb(236, 240, 35); font-size: 1em;"> {{ calculateColumnAverage(index) }}</b>
          </td>
        </tr>
      </template>

    </v-data-table>
  </div>
</template>
<script>

// import campaignType from "../../lib/campaignType";
//import router from "@/router";
// import {secondsToStringDate} from "../../core/secondToDate";
export default {
  data() {
    return {
      dates: [
        new Date(Date.now())
          .addDays(-5)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],
      menu: false,
      all: {},
      format: { id: 0, value: "Number" },
      formatItems: [
        { id: 0, value: "Number" },
        { id: 1, value: "Percent" },
      ],
      headers: [
        { text: "Date", value: "dt" },
        { text: "Day 1", value: "o" },
        { text: "Day 2", value: "t" },
        { text: "Day 3", value: "thr" },
        { text: "Day 4", value: "fo" },
        { text: "Day 5", value: "fv" },
        { text: "Day 6", value: "sx" },
        { text: "Day 7", value: "sv" },
        { text: "Day 8", value: "eg" },
        { text: "Day 9", value: "ni" },
        { text: "Day 10", value: "tn" },
        { text: "Day 11", value: "el" },
        { text: "Day 12", value: "tw" },
        { text: "Day 13", value: "thn" },
        { text: "Day 14", value: "fon" },
        { text: "Day 15", value: "fvn" },
        { text: "Day 16", value: "sxn" },
        { text: "Day 17", value: "svn" },
        { text: "Day 18", value: "egn" },
        { text: "Day 19", value: "nnn" },
        { text: "Day 20", value: "twty" },
        { text: "Day 21", value: "two" },
        { text: "Day 22", value: "twt" },
        { text: "Day 23", value: "twth" },
        { text: "Day 24", value: "twfo" },
        { text: "Day 25", value: "twfv" },
        { text: "Day 26", value: "twsx" },
        { text: "Day 27", value: "twsv" },
        { text: "Day 28", value: "tweg" },
        { text: "Day 29", value: "twnn" },
        { text: "Day 30", value: "thy" },
      ],
      page: 1,
      total: 0,
      options: {},
      pageCount: 0,
      currentPage: 1,
      result: [],
    };
  },


  methods: {

    async initialize() {
      // this.$refs.menu.save(this.dates);
      // this.loading = true;
      // this.result = await this.$store.dispatch("retentions/get",{format:this.format});
      // this.all = this.$store.state.retentions.all;

      this.$refs.menu.save(this.dates);
      await this.$store.dispatch("retentions/get", {
        format: this.format.id,
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
            new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).getTime() / 1000
      });
      this.all = this.$store.state.retentions.all;
      this.result = this.$store.state.retentions.agg;
      // eslint-disable-next-line no-console
      console.log("result :" + this.result);
    },
    convertTRDate(date) {
      var inputDate = new Date(date);

      var formattedDate = new Intl.DateTimeFormat('tr-TR').format(inputDate);
      return formattedDate;
    },

    calculateColumnAverage(index) {
      // // eslint-disable-next-line no-console
      // console.log("index :"+index, "index value :"+averages[index]);
      const averages = this.$store.state.retentions.agg;
      const averageValue = averages[index];
      return  averageValue==0?"":averageValue;
    },

    getRetentionValue(item, day) {

      return item.days[day] == 0 ? "" : this.format.id === 0 ? item.days[day] : `%${item.days[day]}`;

    },
    getColor(item, day) {

      if (this.format.id === 1) {
        if (item.days[day] >= 80) {
          return "green";
        }
        else if (item.days[day] >= 50 && item.days[day] <= 80) {
          return "lima";
        } else if (item.days[day] >= 30 && item.days[day] <= 50) {
          return "orange";
        } else {
          return "red";
        }
      }
      else {
        const percent = Math.round(item.days[day] / item.tdrc * 100, 2)

        if (percent >= 80) {
          return "green";
        }
        else if (percent >= 50 && percent <= 80) {
          return "lima";
        } else if (percent >= 30 && percent <= 50) {
          return "orange";
        } else {
          return "red";
        }
      }

    }
  },

  async mounted() {
    await this.initialize();
  },

};

</script>
